import React from 'react';
import {
  AppBar,
  Box,
  createTheme,
  Stack,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material';

const darkTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#212121',
    },
  },
});

export const SignNavigationBar = () => (
  <Box sx={{ flexGrow: 1 }}>
    <ThemeProvider theme={darkTheme}>
      <AppBar position="static">
        <Toolbar>
          <Stack direction="row" alignItems="center" spacing={0}>
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'revert',
                fontWeight: 750,
                fontSize: '28px',
                color: '#FFFFFF',
              }}
              component="text"
            >
              EK-Tester
            </Typography>
          </Stack>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  </Box>
);
