import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card, Divider, Stack, Typography } from '@mui/material';
import { MyButton } from '../UI/MyButton';
import { MyPasswordTextField } from '../UI/MyPasswordTextField';
import { MyTextFiled } from '../UI/MyTextField';
import { IAlertProps } from '../../interface/IAlertProps';
import { getDefaultAlertProps } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import {
  IP_LOCAL_NAME,
  LEVEL_ADMIN,
  LEVEL_READ,
  MAIN_COLOR_STRING,
  ROOT_NAME,
  TOKEN_NAME,
} from '../../constant/constants';
import { SignInDto } from '../../dto/SignInDto';
import { MAIN_ROUTE } from '../../constant/routes';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';

export const SignInDiv = () => {
  localStorage.clear();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [signInDto, setSignInDto] = useState<SignInDto>({
    localIp: localStorage.getItem(IP_LOCAL_NAME) ?? '',
    login: '',
    password: '',
  });
  const [checkHelper, setCheckHelper] = useState(false);

  const handleSignIn = async () => {
    if (signInDto.login === '' || signInDto.password === '') {
      setCheckHelper(true);
      return;
    }
    if (signInDto.login === 'admin' && signInDto.password === 'A!sds3#') {
      localStorage.setItem(
        TOKEN_NAME,
        'ssfsafhk23o8iulsahdoi727847o32iu25oiuoieauhdjs'
      );
      localStorage.setItem(ROOT_NAME, LEVEL_ADMIN);
      navigate(MAIN_ROUTE);
    } else if (
      signInDto.login === 'user' &&
      signInDto.password === 'ek270_2013'
    ) {
      localStorage.setItem(
        TOKEN_NAME,
        'ssfsafhk23o8iulsahdoi727847o32iu25oiuoieauhdjs'
      );
      localStorage.setItem(ROOT_NAME, LEVEL_READ);
      navigate(MAIN_ROUTE);
    } else {
      setAlertProps({
        message: t('errotNotFoundAccount'),
        severity: AlertSeverityEnum.error,
      });
    }
    // await signIn();
  };

  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Card style={{ padding: '40px', width: '15%', minWidth: '250px' }}>
        <Stack width="100%" spacing={2}>
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '24px',
              letterSpacing: 1,
              color: '#000000',
            }}
            component="text"
          >
            {t('signIn')}
          </Typography>
          <Divider />
          <MyTextFiled
            value={signInDto.login}
            onChange={(e) =>
              setSignInDto({ ...signInDto, login: e.target.value })
            }
            autoComplete="on"
            size="small"
            error={checkHelper}
            label={t('login')}
          />
          <MyPasswordTextField
            value={signInDto.password}
            onChange={(e) =>
              setSignInDto({ ...signInDto, password: e.target.value })
            }
            size="small"
            error={checkHelper}
            label={t('password')}
          />
          <MyButton
            text={t('enter')}
            background={MAIN_COLOR_STRING}
            onClick={handleSignIn}
          />
        </Stack>
      </Card>
    </Stack>
  );
};
