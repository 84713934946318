import i18n from 'i18next';
import {
  ACTION_MAGNET_FLOW,
  ACTION_TEMP,
  LANGUAGE_RUS,
  MOBILE_VIEW,
  PAD_VIEW,
  PC_VIEW,
  ROOT_NAME,
} from '../constant/constants';
import { TaskDto } from '../dto/TaskDto';
import { AlertSeverityEnum } from '../enum/AlertSeverityEnum';
import { DeviceStageEnum } from '../enum/DeviceStageEnum';
import { IAlertProps } from '../interface/IAlertProps';
import { IApiResult } from '../interface/IApiResult';
import { ReportsTypeEnum } from '../enum/ReportsTypeEnum';
import { ActionMagnetFlowEnum } from '../enum/ActionMagnetFlowEnum';
import { ActionTempEnum } from '../enum/ActionTempEnum';
import { DispEnum } from '../enum/DispEnum';
import { KategoryEnum } from '../enum/KategoryEnum';
import { RootEnum } from '../enum/RootEnum';
import { AccountDto } from '../dto/AccountDto';
import { LogDto } from '../dto/LogDto';
import { GroupTaskDto } from '../dto/GroupTaskDto';
import { ActualVersDto } from '../dto/ActualVersDto';
import { TempMeasPeriod } from '../dto/TempMeasPeriod';
import { DeviceListDto } from '../dto/DeviceListDto';
import { AllCheckDto } from '../dto/AllCheckDto';

export const getAllChecks = (dto: DeviceListDto[]) => {
  const allChecks: AllCheckDto = {
    cntAll: 0,
    cntCurrentYear: 0,
    cntCurrentMonth: 0,
    cntCurrentDay: 0,
    cntLastYear: 0,
    cntLastDay: 0,
    cntLastMonth: 0,
  };
  for (let i = 0; i < dto.length; i += 1) {
    allChecks.cntAll += 1;
    const datetimeMass = dto[i].datetime.split(' ');
    const newMassiv = datetimeMass[0].split('-');
    const today = new Date();
    const year = today.getFullYear().toString();
    const lYear = (today.getFullYear() - 1).toString();
    const month = (today.getMonth() + 1).toString();
    const day = today.getDate().toString();
    if (newMassiv[0] === year) {
      allChecks.cntCurrentYear += 1;
    }
    if (newMassiv[0] === lYear) {
      allChecks.cntLastYear += 1;
    }
    if (newMassiv[1] === month) {
      allChecks.cntCurrentMonth += 1;
    }
    if (
      newMassiv[0] === year &&
      newMassiv[1] === month &&
      newMassiv[2] === day
    ) {
      allChecks.cntCurrentDay += 1;
    }
  }

  return allChecks;
};

export const descrStageItems = (id: string) => {
  switch (id) {
    case '1':
      return i18n.t('stage1');
    case '2':
      return i18n.t('stage2');
    case '3':
      return i18n.t('stage3');
    case '5':
      return i18n.t('stage5');
    case '6':
      return i18n.t('stage6');
    case '8':
      return i18n.t('stage8');
    case '9':
      return i18n.t('stage9');
    case '10':
      return i18n.t('stage10');
    case '11':
      return i18n.t('stage11');
    case '12':
      return i18n.t('stage12');
    case '13':
      return i18n.t('stage13');
    case '14':
      return i18n.t('stage14');
    case '16':
      return i18n.t('stage16');
    case '17':
      return i18n.t('stage17');
    case '18':
      return i18n.t('stage18');
    case '19':
      return i18n.t('stage19');
    case '20':
      return i18n.t('stage20');
    case '21':
      return i18n.t('stage21');
    case '22':
      return i18n.t('stage22');
    case '23':
      return i18n.t('stage23');
    case '24':
      return i18n.t('stage24');
    case '25':
      return i18n.t('stage25');
    case '26':
      return i18n.t('stage26');
    case '27':
      return i18n.t('stage27');
    case '28':
      return i18n.t('stage28');
    case '29':
      return i18n.t('stage29');
    case '30':
      return i18n.t('stage30');
    case '31':
      return i18n.t('stage31');
    default:
      return id;
  }
};

export const descrReult = (id: string) => {
  switch (id) {
    case '0':
      return i18n.t('error');
    case '1':
      return i18n.t('ok');
    default:
      return id;
  }
};

export const formatDateArch = (dateTime?: string) => {
  if (dateTime === undefined) {
    return undefined;
  }
  if (dateTime.length < 5) {
    return dateTime;
  }
  if (dateTime.length < 9) {
    return new Intl.DateTimeFormat('ru-RU', {
      year: 'numeric',
      month: '2-digit',
    }).format(new Date(dateTime));
  }
  return new Intl.DateTimeFormat('ru-RU', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(new Date(dateTime));
};

export const getRowColor = (error: string) => {
  if (error !== '0') {
    return 'stage-red';
  }
  return '';
};

export const getArrayMeasPeriod = () => {
  const tempMeasPeriodArray: TempMeasPeriod[] = [];
  tempMeasPeriodArray.push({
    id: 20,
    name: '20',
  });
  tempMeasPeriodArray.push({
    id: 40,
    name: '40',
  });
  tempMeasPeriodArray.push({
    id: 60,
    name: '60',
  });
  return tempMeasPeriodArray;
};

export const unixToLocal = (unix?: string) => {
  if (unix === undefined) {
    return '';
  }
  const seconds = parseInt(unix, 10) * 1000;
  return new Intl.DateTimeFormat('ru-RU', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(seconds);
};

export const replaceQuoat = (value?: string, type?: number) => {
  if (value === undefined) return '';
  if (type === 0) {
    return value.replaceAll('_', '"');
  }
  return value.replaceAll('"', '_');
};

export const descrLog = (log: string) => {
  switch (log) {
    case 'deleteUser':
      return i18n.t('logDeleteUser');
    case 'logout':
      return i18n.t('logLogout');
    case 'signIn':
      return i18n.t('logSignIn');
    case 'signUp':
      return i18n.t('logSignUp');
    case 'addGroup':
      return i18n.t('logAddGroup');
    case 'deleteGroup':
      return i18n.t('logDeleteGroup');
    case 'addGroupTask':
      return i18n.t('logAddGroupTask');
    case 'updtateUserFlowmeterGroupId':
      return i18n.t('logUpdtateUserFlowmeterGroupId');
    case 'deleteDevice':
      return i18n.t('logDeleteDevice');
    case 'moveToArch':
      return i18n.t('logMoveToArch');
    case 'updateInfo':
      return i18n.t('logUpdateInfo');
    case 'updateSetup':
      return i18n.t('logUpdateSetup');
    case 'deleteSetup':
      return i18n.t('logDeleteSetup');
    default:
      return '';
  }
};

export const descrActualApp = (dto?: ActualVersDto) => {
  if (dto === undefined) {
    return '';
  }
  switch (dto.neoLocalServer) {
    case 'needUpdate':
      return `${i18n.t('needUpdate')} ${dto.neoLocalServerVer}`;
    default:
      return '';
  }
};

export const getGroupTaskDto = (groupNum: string, taskArr: TaskDto[]) => {
  const groupTaskDto: GroupTaskDto = {
    groupNum,
    taskConstPres: '',
    taskConstKomp: '',
    taskContrHour: '',
    taskContrDay: '',
    taskContrVal: '',
    taskServerAddress: '',
    taskMinLevel: '',
    taskMinTempGprs: '',
    taskTempMinLimit: '',
    taskConstTempMin: '',
    taskTOptionMin: '',
    taskTempMaxLimit: '',
    taskConstTempMax: '',
    taskTOptionMax: '',
    taskMagnetOption: '',
  };

  for (let i = 0; i < taskArr.length; i += 1) {
    switch (taskArr[i].id) {
      case 'cp':
        groupTaskDto.taskConstPres = taskArr[i].value;
        break;
      case 'ck':
        groupTaskDto.taskConstKomp = taskArr[i].value;
        break;
      case 'ch':
        groupTaskDto.taskContrHour = taskArr[i].value;
        break;
      case 'cd':
        groupTaskDto.taskContrDay = taskArr[i].value;
        break;
      case 'cv':
        groupTaskDto.taskContrVal = taskArr[i].value;
        break;
      case 's':
        groupTaskDto.taskServerAddress = taskArr[i].value;
        break;
      case 'ml':
        groupTaskDto.taskMinLevel = taskArr[i].value;
        break;
      case 'mt':
        groupTaskDto.taskMinTempGprs = taskArr[i].value;
        break;
      case 'dtl':
        groupTaskDto.taskTempMinLimit = taskArr[i].value;
        break;
      case 'cdt':
        groupTaskDto.taskConstTempMin = taskArr[i].value;
        break;
      case 'adt':
        groupTaskDto.taskTOptionMin = taskArr[i].value;
        break;
      case 'utl':
        groupTaskDto.taskTempMaxLimit = taskArr[i].value;
        break;
      case 'cut':
        groupTaskDto.taskConstTempMax = taskArr[i].value;
        break;
      case 'aut':
        groupTaskDto.taskTOptionMax = taskArr[i].value;
        break;
      case 'am':
        groupTaskDto.taskMagnetOption = taskArr[i].value;
        break;
      default:
        break;
    }
  }
  return groupTaskDto;
};

export const getDeviceStageDescription = (stage: number) => {
  switch (stage) {
    case DeviceStageEnum.ALL:
      return i18n.t('flowSystem');
    case DeviceStageEnum.ARCHIVE:
      return i18n.t('flowArchive');
    case DeviceStageEnum.BATTERY:
      return i18n.t('flowBattery');
    case DeviceStageEnum.CONNECT:
      return i18n.t('flowConnect');
    case DeviceStageEnum.SEARCH:
      return i18n.t('flowSearch');
    case DeviceStageEnum.SERTIFICATE:
      return i18n.t('flowSert');
    case DeviceStageEnum.SERVER_LOGS:
      return i18n.t('flowLog');
    case DeviceStageEnum.TASK:
      return i18n.t('activeFlow');
    case DeviceStageEnum.UPDATE:
      return i18n.t('flowUpdate');
    case DeviceStageEnum.WARNING:
      return i18n.t('flowWarning');
    default:
      return '';
  }
};

export const getReportTypeDescription = (type: number) => {
  switch (type) {
    case ReportsTypeEnum.HOUR:
      return i18n.t('hourArchive');
    case ReportsTypeEnum.DAY:
      return i18n.t('dayArchive');
    case ReportsTypeEnum.MONTH:
      return i18n.t('monthArchive');
    case ReportsTypeEnum.EVENTS:
      return i18n.t('eventsJournal');
    case ReportsTypeEnum.CHANGES:
      return i18n.t('changesJournal');
    default:
      return '';
  }
};

export const getRootDescription = (type: number) => {
  switch (type) {
    case RootEnum.READ:
      return i18n.t('read');
    case RootEnum.READ_WRITE:
      return i18n.t('readWrite');
    case RootEnum.ADMIN:
      return i18n.t('admin');
    default:
      return '';
  }
};

export const getRootLevel = () => {
  const root = parseInt(localStorage.getItem(ROOT_NAME) ?? '-1', 10);
  return root;
};

export const getRootArray = () => {
  const rootArray: string[] = [];
  rootArray.push(i18n.t('read'));
  rootArray.push(i18n.t('readWrite'));
  return rootArray;
};

export const getActionDescription = (type: number) => {
  switch (type) {
    case ActionMagnetFlowEnum.ONLY_JOURNAL:
      return i18n.t('actionJournalOnly');
    case ActionMagnetFlowEnum.CONTR_VAL:
      return i18n.t('actionContrVal');
    case ActionTempEnum.ONLY_JOURNAL:
      return i18n.t('actionJournalOnly');
    case ActionTempEnum.CONTR_VAL:
      return i18n.t('actionContrVal');
    case ActionTempEnum.CONST_TEMP:
      return i18n.t('actionConstTemp');
    default:
      return '';
  }
};

export const getDispOnDescription = (type: number) => {
  switch (type) {
    case DispEnum.OFF:
      return i18n.t('dispOnStatusOff');
    case DispEnum.ON:
      return i18n.t('dispOnStatusOn');
    default:
      return '';
  }
};

export const getDispRotateDescription = (type: number) => {
  switch (type) {
    case DispEnum.OFF:
      return i18n.t('dispRotateStatusOff');
    case DispEnum.ON:
      return i18n.t('dispRotateStatusOn');
    default:
      return '';
  }
};

export const workTime = (time?: string) => {
  if (time === undefined) {
    return '';
  }
  const seconds = parseInt(time, 10);
  return seconds / 3600;
};

export const getDescrPlombNum = (plombnum?: string) => {
  if (plombnum === undefined) {
    return '';
  }
  if (plombnum === '0') {
    return i18n.t('plombBroken');
  }
  return plombnum;
};

export const getDispRotateArray = () => {
  const dispRotateArray: string[] = [];
  dispRotateArray.push(i18n.t('dispRotateStatusOff'));
  dispRotateArray.push(i18n.t('dispRotateStatusOn'));
  return dispRotateArray;
};

export const getDispOnArray = () => {
  const dispOnArray: string[] = [];
  dispOnArray.push(i18n.t('dispOnStatusOff'));
  dispOnArray.push(i18n.t('dispOnStatusOn'));
  return dispOnArray;
};

export const getMagnetFlowActionArray = () => {
  const actionArray: string[] = [];
  actionArray.push(i18n.t('actionJournalOnly'));
  actionArray.push(i18n.t('actionContrVal'));
  return actionArray;
};

export const getTempActionArray = () => {
  const actionArray: string[] = [];
  actionArray.push(i18n.t('actionJournalOnly'));
  actionArray.push(i18n.t('actionContrVal'));
  actionArray.push(i18n.t('actionConstTemp'));
  return actionArray;
};

export const getReportTypeArray = () => {
  const reportType: string[] = [];
  reportType.push(i18n.t('hourArchive'));
  reportType.push(i18n.t('dayArchive'));
  reportType.push(i18n.t('monthArchive'));
  reportType.push(i18n.t('eventsJournal'));
  reportType.push(i18n.t('changesJournal'));
  return reportType;
};

export const isMobileView = () => {
  const result = window.innerWidth < 820;
  return result;
};

export const otherToDataGrid = {
  autoHeight: true,
  showCellRightBorder: true,
  showColumnRightBorder: true,
};

export const getViewType = () => {
  const result = window.innerWidth;
  if (result < 820) {
    return MOBILE_VIEW;
  }
  if (result < 1440) {
    return PAD_VIEW;
  }
  return PC_VIEW;
};

export const getSelectedLng = () => {
  let lng = localStorage.getItem('language');
  if (lng === null) {
    lng = LANGUAGE_RUS;
  }
  return lng;
};

export const getMainGridSize = () => {
  const result = getViewType();
  if (result === MOBILE_VIEW) {
    return 12;
  }
  if (result === PAD_VIEW) {
    return 6;
  }
  return 4;
};

export const getDefaultAlertProps = () => {
  const alertApp: IAlertProps = {
    message: '',
    severity: AlertSeverityEnum.error,
  };
  return alertApp;
};

export const getDefaultApiResult = () => {
  const apiResult: IApiResult = {
    isSuccess: false,
    status: -1,
    data: null,
    alert: getDefaultAlertProps(),
  };
  return apiResult;
};

export const changeFormatDateWithDiff = (inputValue?: string) => {
  let result = '';
  if (inputValue) {
    const arrayDate = inputValue.split('.');
    if (arrayDate.length === 3) {
      result = `${arrayDate[2]}-${arrayDate[1]}-${arrayDate[0]}`;
    } else {
      result = inputValue;
    }
  }
  return result;
};

export const changeFormatDateWithPoint = (inputValue?: string) => {
  let result = '';
  if (inputValue) {
    const arrayDate = inputValue.split('-');
    if (arrayDate.length === 3) {
      result = `${arrayDate[0]}.${arrayDate[1]}.${arrayDate[2]}`;
    } else {
      result = inputValue;
    }
  }
  return result;
};

export const setToken = (tokenType: string) =>
  `${localStorage.getItem(tokenType)}`;

export const getSimvol = (length: number) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getConnectStatus = (
  numVu: number,
  connectDate: string,
  isHeader?: boolean
) => {
  const nowDate = new Date().getTime();
  const connectTwo = Date.parse(connectDate);
  // const offset = new Date().getTimezoneOffset();
  const leftHour = (nowDate - connectTwo) / 1000 / 3600;
  if (isHeader !== undefined && isHeader) {
    return 'header-gsp';
  }
  if (numVu > 4) {
    if (leftHour > 24 && leftHour < 48) {
      return 'stage-orange';
    }
    if (leftHour >= 48) {
      return 'stage-red';
    }
  }
  if (numVu <= 4) {
    if (leftHour > 1 && leftHour < 3) {
      return 'stage-orange';
    }
    if (leftHour >= 3) {
      return 'stage-red';
    }
  }
  return 'stage-green';
};

export const getReverseColor = (isReverse: number, isGsp: boolean) => {
  if (isReverse === 1) {
    return 'stage-red';
  }
  if (isGsp) {
    return 'header-gsp';
  }
  return 'stage-green';
};

export const getNameArray = (array: any, hasAll?: boolean, sortId?: number) => {
  const arrName = [];
  if (hasAll) {
    arrName.push('Все');
  }
  for (let i = 0; i < array.length; i += 1) {
    if (sortId !== undefined) {
      if (sortId === array[i].divisionId) {
        arrName.push(array[i].name.replaceAll('_', '"'));
      }
    } else {
      arrName.push(array[i].name.replaceAll('_', '"'));
    }
  }
  return arrName;
};

export const getNameTaskArray = (array: any) => {
  const arrName = [];

  for (let i = 0; i < array.length; i += 1) {
    arrName.push(array[i].name);
  }
  return arrName;
};

export const getTypeTaskArray = (array: any) => {
  const arrName = [];

  for (let i = 0; i < array.length; i += 1) {
    arrName.push(array[i].type);
  }
  return arrName;
};

export const getSpinnerTaskArray = (array: any) => {
  const arrName = [];

  for (let i = 0; i < array.length; i += 1) {
    arrName.push(array[i].spinner);
  }
  return arrName;
};

export const getTooltipErrors = (errors?: string) => {
  if (errors === undefined) {
    return undefined;
  }
  if (errors === '0') {
    return undefined;
  }
  const arrErr = errors.split(',');
  let result = '';
  for (let i = 0; i < arrErr.length; i += 1) {
    arrErr[i] = arrErr[i].replace(' ', '');
    if (arrErr[i] === '1') {
      result = `${result}${i18n.t('errFlowMax')},\n`;
    }
    if (arrErr[i] === '2') {
      result = `${result}${i18n.t('errTempLimit')},\n`;
    }
    if (arrErr[i] === '3') {
      result = `${result}${i18n.t('errMagnet')},\n`;
    }
    if (arrErr[i] === '4') {
      result = `${result}${i18n.t('errCalibErr')},\n`;
    }
    if (arrErr[i] === '5') {
      result = `${result}${i18n.t('errBox')},\n`;
    }
    if (arrErr[i] === '6') {
      result = `${result}${i18n.t('errMinFlow')},\n`;
    }
    if (arrErr[i] === '7') {
      result = `${result}${i18n.t('errAdc')},\n`;
    }
    if (arrErr[i] === '8') {
      result = `${result}${i18n.t('errBox2')},\n`;
    }
  }
  if (arrErr.length > 0) {
    result = result.substring(0, result.length - 2);
  }
  return result;
};

export const getIdTaskArray = (array: any) => {
  const arrId = [];
  for (let i = 0; i < array.length; i += 1) {
    arrId.push(array[i].id);
  }
  return arrId;
};

export const formTaskArray = (arrayChoose?: TaskDto[]) => {
  const tasks: TaskDto[] = [];
  tasks.push({
    id: 'cp',
    name: i18n.t('constPres'),
    type: 'number',
  });
  tasks.push({
    id: 'ck',
    name: i18n.t('constKomp'),
    type: 'number',
  });
  tasks.push({
    id: 'ch',
    name: i18n.t('contrHour'),
    type: 'number',
  });
  tasks.push({
    id: 'cd',
    name: i18n.t('contrDay'),
    type: 'number',
  });
  tasks.push({
    id: 'cv',
    name: i18n.t('contrVal'),
    type: 'number',
  });
  tasks.push({
    id: 's',
    name: i18n.t('server'),
    type: 'text',
  });
  tasks.push({ id: 'ml', name: i18n.t('minSign'), type: 'number' });
  tasks.push({ id: 'mt', name: i18n.t('minGprsTemp'), type: 'number' });
  tasks.push({
    id: 'dtl',
    name: i18n.t('minTempLimit'),
    type: 'number',
  });
  tasks.push({
    id: 'cdt',
    name: i18n.t('constMinTemp'),
    type: 'number',
  });
  tasks.push({
    id: 'adt',
    name: i18n.t('actionMinTemp'),
    type: 'number',
    spinner: ACTION_TEMP,
  });
  tasks.push({
    id: 'utl',
    name: i18n.t('maxTempLimit'),
    type: 'number',
  });
  tasks.push({
    id: 'cut',
    name: i18n.t('constMaxTemp'),
    type: 'number',
  });
  tasks.push({
    id: 'aut',
    name: i18n.t('actionMaxTemp'),
    type: 'number',
    spinner: ACTION_TEMP,
  });
  tasks.push({
    id: 'am',
    name: i18n.t('actionMagnet'),
    type: 'number',
    spinner: ACTION_MAGNET_FLOW,
  });
  if (arrayChoose) {
    for (let i = 0; i < arrayChoose.length; i += 1) {
      tasks.filter((a) => a.id !== arrayChoose[i].id);
    }
  }
  return tasks;
};

export const fromAccountDto = () => {
  const accountDto: AccountDto[] = [];
  for (let i = 0; i < 20; i += 1) {
    if (i === 0) {
      accountDto.push({
        id: 1,
        login: 'test1',
        name: 'test1',
        root: 0,
        password: '',
      });
    } else {
      accountDto.push({
        id: 2,
        login: 'test1',
        name: 'test1',
        root: 1,
        password: '',
      });
    }
  }
  return accountDto;
};

export const formLogDto = () => {
  const logDto: LogDto[] = [];
  for (let i = 0; i < 20; i += 1) {
    logDto.push({
      id: 1,
      datetime: '01.01.2022 00:00:00',
      user: 'user1',
      action: '1',
      data: '',
    });
  }
  return logDto;
};

export const getKategoryDescription = (type: number) => {
  switch (type) {
    case KategoryEnum.NOT_SELECTED:
      return i18n.t('notChoose');
    case KategoryEnum.PEOPLE:
      return i18n.t('people');
    case KategoryEnum.INDUSTRY:
      return i18n.t('factory');
    default:
      return '';
  }
};

export const getKategoryArray = () => {
  const kategDto: string[] = [];
  kategDto.push(i18n.t('notChoose'));
  kategDto.push(i18n.t('people'));
  kategDto.push(i18n.t('factory'));
  return kategDto;
};

export const getIdArray = (array: any, hasAll?: boolean) => {
  const arrId = [];
  if (hasAll) {
    arrId.push(-1);
  }
  for (let i = 0; i < array.length; i += 1) {
    arrId.push(array[i].id);
  }
  return arrId;
};

export const getNumberFromLocal = (name: string) => {
  let result = localStorage.getItem(name) || '-1';
  if (Number.isNaN(parseInt(result, 10))) {
    result = '-1';
  }
  return parseInt(result, 10);
};

export const getBoardVersion = (fw: string) => {
  const ver = parseInt(fw, 10);
  // eslint-disable-next-line no-bitwise
  const byte1 = (ver & 0xff000000) >> 24;
  // eslint-disable-next-line no-bitwise
  const byte2 = (ver & 0x00ff0000) >> 16;
  return `${byte1}.${byte2}`;
};

export const getFwVersion = (fw: string) => {
  const ver = parseInt(fw, 10);
  // eslint-disable-next-line no-bitwise
  const byte1 = (ver & 0x0000ff00) >> 8;
  // eslint-disable-next-line no-bitwise
  const byte2 = ver & 0x000000ff;
  return `${byte1}.${byte2}`;
};

export const getDefaultDateToReport = (day: number) => {
  const nowDate = new Date().getTime();
  const nowFormatDate = new Date(nowDate - day * 24 * 60 * 60 * 1000);
  const result = nowFormatDate.toLocaleDateString();
  return changeFormatDateWithDiff(result);
};

export const getHartState = (state: string) => {
  const arrayState = state.split(';');
  let result = '';
  if (arrayState.length === 6) {
    result = `канал 1 - ${arrayState[3]}/${arrayState[0]}
    канал 2 - ${arrayState[4]}/${arrayState[1]}
    канал 3 - ${arrayState[5]}/${arrayState[2]}`;
  }
  if (arrayState.length === 8) {
    result = `канал 1 - ${arrayState[4]}/${arrayState[0]}
    канал 2 - ${arrayState[5]}/${arrayState[1]}
    канал 3 - ${arrayState[6]}/${arrayState[2]}
    канал 4 - ${arrayState[7]}/${arrayState[3]}`;
  }
  return result;
};

export const logDescription = (log: string) => {
  switch (log) {
    case 'signIn':
      return 'Авторизация';
    case 'regModem 0':
      return 'ВУ снят с регистрации';
    case 'regModem 1':
      return 'ВУ зарегистрирован';
    case 'removeControl':
      return 'ВУ убран из сигнализации контроля границ';
    case 'removeSetup':
      return 'ВУ убран из сигнализации изменения настроек';
    case 'removeModemTask':
      return 'ВУ снят с активного задания';
    case 'updateInfo':
      return 'Обновление информации ВУ';
    case 'updateSetup':
      return 'Обновление настроек модема';
    case 'createTask':
      return 'Добавлено групповое задание ';
    case 'deleteTask':
      return 'Удалено групповое задание ';
    default:
      return log;
  }
};

export const roundValue = (value: any, point: number) => {
  if (Number.isNaN(parseFloat(value))) {
    return 'Нет данных';
  }
  const result = Math.floor(value * point) / point;
  return result.toLocaleString();
};
function moment(arg0: string) {
  throw new Error('Function not implemented.');
}
