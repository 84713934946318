import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { MAIN_ROUTE, SIGN_IN_ROUTE } from '../../constant/routes';
import { MainPage } from '../../page/MainPage';
import { SignInPage } from '../../page/SignInPage';

export const App = () => (
  <div>
    <Routes>
      <Route path={SIGN_IN_ROUTE} element={<SignInPage />} />
      <Route path={MAIN_ROUTE} element={<MainPage />} />
    </Routes>
  </div>
);

export default App;
