import React, { FunctionComponent, useState } from 'react';
import { Card, Divider, IconButton, Stack, Typography } from '@mui/material';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { getAllChecks } from '../../utils/utils';
import { DeviceListDto } from '../../dto/DeviceListDto';
import { AllCheckDto } from '../../dto/AllCheckDto';
import { MyTextFiled } from '../UI/MyTextField';
import { MAIN_COLOR_STRING } from '../../constant/constants';
import { DialogInfo } from '../dialog/DialogInfo';

interface Props {
  dto: DeviceListDto[];
}

export const MainDivItem: FunctionComponent<Props> = ({ dto }) => {
  const [searchId, setSeacrhId] = useState('');
  const cntAllCheck: AllCheckDto = getAllChecks(dto);
  const [deviceDto, setDeviceDto] = useState<DeviceListDto>();
  const [openDialog, setOpenDialog] = useState(false);

  const findId = () => {
    for (let i = 0; i < dto.length; i += 1) {
      if (dto[i].procId === searchId) {
        setDeviceDto(dto[i]);
      }
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <Stack spacing={2}>
      <Card style={{ padding: '10px' }}>
        <Stack spacing={1}>
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontSize: '20px',
              fontWeight: 600,
              color: '#000000',
            }}
            component="text"
          >
            Проверено итого плат {cntAllCheck.cntAll}
          </Typography>
          <Divider />
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontSize: '14px',
              color: '#000000',
            }}
            component="text"
          >
            Проверено в прошлом году {cntAllCheck.cntLastYear}
          </Typography>
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontSize: '14px',
              color: '#000000',
            }}
            component="text"
          >
            Проверено в текущем году {cntAllCheck.cntCurrentYear}
          </Typography>
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontSize: '14px',
              color: '#000000',
            }}
            component="text"
          >
            Проверено в текущем месяце {cntAllCheck.cntCurrentMonth}
          </Typography>
          <Typography
            style={{
              fontFamily: 'sans-serif',
              fontSize: '14px',
              color: '#000000',
            }}
            component="text"
          >
            Проверено сегодня {cntAllCheck.cntCurrentDay}
          </Typography>
        </Stack>
      </Card>
      <Stack direction="row" alignItems="center" spacing={1}>
        <MyTextFiled
          value={searchId}
          onChange={(e) => setSeacrhId(e.target.value)}
          autoComplete="off"
          size="small"
          label="Введите ID процессора"
        />
        <IconButton
          size="large"
          sx={{ color: MAIN_COLOR_STRING }}
          onClick={findId}
        >
          <FindInPageIcon fontSize="large" />
        </IconButton>
      </Stack>
      {deviceDto !== undefined && (
        <Card style={{ padding: '10px' }} onClick={() => handleOpenDialog()}>
          <Stack spacing={1}>
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontSize: '20px',
                fontWeight: 600,
                color: '#000000',
              }}
              component="text"
            >
              Результат поиска
            </Typography>
            <Divider />
            <Stack spacing={1}>
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontSize: '14px',
                  color: '#000000',
                }}
                component="text"
              >
                ID процессора {deviceDto?.procId}
              </Typography>
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontSize: '14px',
                  color: '#000000',
                }}
                component="text"
              >
                Дата проверки {deviceDto?.datetime}
              </Typography>
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontSize: '14px',
                  color: '#000000',
                }}
                component="text"
              >
                Количество проверок {deviceDto?.cnt}
              </Typography>
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontSize: '14px',
                  color: '#000000',
                }}
                component="text"
              >
                Заключение {deviceDto?.resultWord}
              </Typography>
            </Stack>
          </Stack>
        </Card>
      )}
      <DialogInfo
        open={openDialog}
        isShort
        deviceDto={deviceDto}
        onClose={handleCloseDialog}
      />
    </Stack>
  );
};
