import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import { DialogHeaderDiv } from '../dialog-header/DialogHeaderDiv';
import { DeviceListDto } from '../../dto/DeviceListDto';
import { DialogItemInfo } from './DialogItemInfo';

interface Props {
  open: boolean;
  onClose: () => void;
  deviceDto?: DeviceListDto;
  isShort?: boolean;
}

export const DialogInfo: FunctionComponent<Props> = ({
  open,
  onClose,
  deviceDto,
  isShort,
}) => {
  const stages = deviceDto?.message ?? [];
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        style={{ userSelect: 'none' }}
        PaperProps={{
          sx: { backgroundColor: '#e7ebee', height: '100%', width: '100%' },
        }}
      >
        <DialogTitle>
          {!isShort && (
            <DialogHeaderDiv
              title={`${t('procId')} ${deviceDto?.procId ?? ''}`}
              onClose={handleClose}
            />
          )}
          {isShort && (
            <Stack width="100%" direction="row">
              <div style={{ flex: 1 }} />
              <div>
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </div>
            </Stack>
          )}
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={1}>
            {stages.map((item) => (
              <DialogItemInfo
                key={item}
                isShort={isShort === true}
                stage={item}
              />
            ))}
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

DialogInfo.defaultProps = {
  deviceDto: undefined,
  isShort: false,
};
