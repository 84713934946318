import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getSelectedLng } from '../utils/utils';
import { LANGUAGE_RUS } from '../constant/constants';

i18n.use(initReactI18next).init({
  resources: {
    rus: {
      translation: {
        signIn: 'Авторизация',
        login: 'Логин',
        password: 'Пароль',
        enter: 'Войти',

        procId: 'ID процессора',
        datetime: 'Дата проверки',
        appVer: 'Версия приложения',
        cnt: 'Количество проверок',
        message: 'Контрольные точки',
        result: 'Результат',
        ok: 'Годен',
        error: 'Не годен',

        errotNotFoundAccount: 'Такого личного кабинет нет',

        stage1: 'Пункт 2.1 ПИ. Проверка потребляемого тока без прошивки в мА',
        stage2: 'Пункт 2.2 ПИ. Загрузка прошивки',
        stage3: 'Пункт 2.3 ПИ. Подключение по оптическому кабелю (интерфейс 1)',
        stage5: 'Пункт 2.4 ПИ. Проверка калибровочного замка',
        stage6: 'Пункт 2.5 ПИ. Проводят запись настроек из wpp файла',
        stage8: 'Пункт 2.6 ПИ. Проверка диcплея (битые пиксели)',
        stage9: 'Пункт 2.7 ПИ. Проверка диcплея (контрасттность 50)',
        stage10: 'Пункт 2.8 ПИ. Проверка диcплея (контрасттность 250)',
        stage11: 'Пункт 2.9 ПИ. Проверка импульсного входа 1',
        stage12: 'Пункт 2.10 ПИ. Проверка импульсного входа 2',
        stage13: 'Пункт 2.11 ПИ. Проверка статусного входа 3',
        stage14:
          'Пункты 2.12, 2.13, 2.14 и 2.15 ПИ. Проверка выходов DA1, DA2, DA3, DA4',
        stage16:
          'Пункты 2.17, 2.18, 2.19 и 2.20 ПИ. Проверка каналов измерения P1, T1, T2 и канала перепада давления',
        stage17:
          'Пункты 2.9, 2.10 и 2.11 ПИ. Проверка импульсных входов 1 и 2, статусного входа 3',
        stage18: 'Пункт 2.16 ПИ. Проверка кнопок (кроме ESC)',
        stage19: 'Пункт 2.16 ПИ. Проверка кнопки ESC',
        stage20: 'Пункт 2.17 ПИ. Проверка канала измерения P1',
        stage21: 'Пункт 2.18 ПИ. Проверка канала измерения T1',
        stage22: 'Пункт 2.19 ПИ. Проверка канала измерения T2',
        stage23: 'Пункт 2.20 ПИ. Проверка канала перепада давления',
        stage24: 'Пункт 2.21 ПИ. Проверка внешнего питания',
        stage25: 'Пункт 2.22 ПИ. Проверка RTC',
        stage26: 'Пункт 2.23 ПИ. Проверка FRAM памяти',
        stage27: 'Пункт 2.24 ПИ. Проверка FLASH памяти',
        stage28: 'Пункт 2.25 ПИ. Проверка интерфейса 2 (модем)',
        stage29: 'Пункт 2.26 ПИ. Проверка интерфейса 2 (МТЭК)',
        stage30: 'Пункт 2.27 ПИ. Заводской сброс',
        stage31: 'Пункт 2.28 ПИ. Проверка базового тока потребления в мкА',

        error500:
          'Произошла ошибка, это все что мы знаем... Попробуйте повторить запрос позже',
      },
    },
  },
  lng: getSelectedLng(),
  fallbackLng: LANGUAGE_RUS,
});

export default i18n;
