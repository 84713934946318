import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Box,
  createTheme,
  IconButton,
  Stack,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { SIGN_IN_ROUTE } from '../../constant/routes';
import { getDefaultAlertProps } from '../../utils/utils';
import { IAlertProps } from '../../interface/IAlertProps';
import { MyAlert } from '../UI/MyAlert';

export const MainNavigationBar = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );

  const darkTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#212121',
      },
    },
  });

  const handleLogout = () => {
    navigate(SIGN_IN_ROUTE);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <ThemeProvider theme={darkTheme}>
        <AppBar position="static">
          <Toolbar>
            <Stack direction="row" width="100%" justifyContent="space-between">
              <Stack direction="row" alignItems="center" spacing={2}>
                <Stack direction="row" alignItems="center" spacing={0}>
                  <Typography
                    style={{
                      fontFamily: 'sans-serif',
                      fontStyle: 'revert',
                      fontWeight: 750,
                      fontSize: '28px',
                      color: '#FFFFFF',
                    }}
                    component="text"
                  >
                    EK-Tester
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <IconButton
                  size="large"
                  title={t('tooltipExit')}
                  edge="start"
                  color="inherit"
                  onClick={handleLogout}
                  aria-label="menu"
                >
                  <LogoutIcon />
                </IconButton>
              </Stack>
            </Stack>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    </Box>
  );
};
