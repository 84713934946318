import React, { FunctionComponent } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { descrStageItems } from '../../utils/utils';
import { MAIN_COLOR_STRING } from '../../constant/constants';

interface Props {
  stage: string;
  isShort: boolean;
}

export const DialogItemInfo: FunctionComponent<Props> = ({
  stage,
  isShort,
}) => {
  const items = stage.split('*,*');
  return (
    <div hidden={items[2] === ''}>
      <Stack spacing={1}>
        {items.length === 1 && (
          <Stack padding="5px">
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontSize: '14px',
                fontWeight: 600,
                color: MAIN_COLOR_STRING,
              }}
              component="text"
            >
              Повторная проверка
            </Typography>
            <Divider />
          </Stack>
        )}
        {items.length === 3 && (
          <Stack
            direction={isShort ? 'column' : 'row'}
            justifyContent="space-between"
          >
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontSize: '14px',
                fontWeight: 600,
                color: '#000000',
              }}
              component="text"
            >
              {descrStageItems(items[0])}
            </Typography>
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontSize: '12px',
                color: items[2] === 'ОК' ? 'green' : 'red',
              }}
              component="text"
            >
              {items[1]}
            </Typography>
            {isShort && (
              <Typography
                style={{
                  fontFamily: 'sans-serif',
                  fontSize: '14px',
                  color: items[2] === 'ОК' ? 'green' : 'red',
                }}
                component="text"
              >
                {items[2] === 'ОК'
                  ? 'Результаты положительные'
                  : 'Результаты отрицательные'}
              </Typography>
            )}
          </Stack>
        )}
      </Stack>
    </div>
  );
};
